const CLIENT_CONFIG = {
    name: 'Fattoria Dei Talenti',
    id_company: 7,
    numTutorialImages: 8,
    showAvatarCampaigns: true,
    apiEndpoint: 'https://api-fdt.whappy.it/api/',
    primaryColor: "#77c02d",
    primaryColor_2: "#bcb32d",
    secondaryColor: "#095540",
    secondaryColor_2: "#77c02d",
    thirdaryColor: "#43959d",
    thirdaryColor_2: "#77c02d",
    graphLightColor_1:'#77c02d',
    graphLightColor_2:'#095540',
    graphDarkColor_1:'#fff',
    graphDarkColor_2:"#ffc105"
}
export default CLIENT_CONFIG;
